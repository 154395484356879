<template>
  <v-layout column>
    <v-layout align-center class="my-3">
      <DefaultSubtitle ellipsis>{{ name }}</DefaultSubtitle>
      <IconButton
        v-if="!isDrawingField && !hideHistory"
        icon="mdi-history"
        @clicked="onHistory"
        bgcolor="transparent"
        noYMargin
        noXMargin
        class="mx-1"
      ></IconButton>
      <PatientCardFlag
        v-if="showFlags"
        :value.sync="session.yellow"
        :disabled="isSpectatorMode"
        color="#FEC36C"
      ></PatientCardFlag>
      <PatientCardFlag
        v-if="showFlags"
        :value.sync="session.red"
        :disabled="isSpectatorMode"
        color="#E46464"
      ></PatientCardFlag>
      <DuplicationButton
        v-if="isTextField && !isSpectatorMode && !hideDuplicate"
        :continueMode="field.duplication"
        :name="label"
        :model="field.name"
        :value="field.data.text"
        :patient="patient"
        :session="session"
        :duplication.sync="field.duplication"
        class="mx-1"
      ></DuplicationButton>
      <InfoButton class="mx-1" v-if="info" :text="info" noMargin></InfoButton>
      <AutosaveIcon v-if="autosave" class="ml-3" :value="status"></AutosaveIcon>
      <v-spacer></v-spacer>
      <slot v-if="!$vuetify.breakpoint.xsOnly"></slot>
      <v-layout v-if="!hideCollapseBtn && !isSpectatorMode" shrink align-center>
        <IconButton
          :icon="collapsed ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          @clicked="onCollapse"
          bgcolor="transparent"
          noYMargin
          noXMargin
        ></IconButton>
      </v-layout>
    </v-layout>
    <slot v-if="$vuetify.breakpoint.xsOnly"></slot>
  </v-layout>
</template>

<script>
import URO_NAMES from "@/constants/patientcard/uro";
import PHY_NAMES from "@/constants/patientcard/physio";
import PED_NAMES from "@/constants/patientcard/pediatric";
import {
  FIELD_TYPES,
  DRAWING_FIELDS,
} from "@/constants/patientcard/fields/types";

export default {
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    collapsed: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideDuplicate: {
      type: Boolean,
      default: false,
    },
    status: {},
    autosave: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
    hideHistory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    label() {
      if (this.session.session_type == 0) {
        return PHY_NAMES[this.field.name] || this.field.label;
      } else if (this.session_type == 1) {
        return URO_NAMES[this.field.name] || this.field.label;
      } else {
        return PED_NAMES[this.field.name] || this.field.label;
      }
    },
    isSpectatorMode() {
      return !!this.owner;
    },
    isTextField() {
      return this.field.field_type === FIELD_TYPES.TEXT_FIELD;
    },
    isDrawingField() {
      return DRAWING_FIELDS.includes(this.field.field_type);
    },
    showFlags() {
      return this.field.name === "InterviewField";
    },
    info() {
      if (!this.field.builtin) {
        return "";
      }

      switch (this.field.name) {
        case "InterviewField":
          return "<b>Wywiad</b> to najważniejsza część badania każdego fizjoterapeuty. W wywiadzie powinny się znaleźć takie informacje jak: <br> \
          rozpoznanie choroby, urazu, problemu zdrowotnego<br> stan funkcjonowania pacjenta, przyczyny, z powodu których pacjent zgłosił się na fizjoterapię ustalenie celu fizjoterapii, oczekiwania pacjenta odnośnie fizjoterapii.";
        case "FunctionalTest":
          return 'W tym polu podkreśl swoje obserwacje z badania w zakresie jego stanu funkcjonalnego. Jeśli przykładowy pacjent skarży się na ból w odcinku szyjnym, to badaniem dla takiego pacjenta będą między innymi czynne i bierne ruchy głową.<br>\
          Zapis z takiego badania możesz wykonać w sposób:<br>\
          "Ograniczenie rotacji głowy w prawo (45 stopni) ból 7/10 w skali VAS."<br>\
          Następnie po zabiegu wykonaj test ponownie i zapisz zmiany jakie nastąpiły w badaniu.<br>\
          Dobrze będzie również dopisać hipotezę, która Twoim zdaniem tłumaczy problem pacjenta.<br>\
          Dla naszego przykładowego pacjenta może to być "przeciążenie mięśniowe".<br>\
          Pole "<b>badanie funkcjonalne</b>" powinno być skorelowane z kodami ICF.';
        case "Plan":
          return 'Tutaj opisz formy terapii jakie zamierzasz wykorzystać w pracy pacjentem w aktualnej wizycie oraz podstawowe cele,\
           które chcesz osiągnąć na danej wizycie. Na przykład "zmniejszenie dolegliwości bólowych".\
            Jeżeli zrealizowanie danego celu nie jest możliwe na jednej wizycie, to zapisz cel długofalowy. W przypadku pacjenta, \
            którego należy spionizować pierwszym takim planem fizjoterapii może być "uzyskanie stabilizacji podczas siadu".';
        case "TherapyField":
          return "Czyli opis udzielonych świadcześ zdrowotnych. Opisz tutaj przebieg terapii i wszystkie procedury, jakie zostały wykonane podczas wizyty.";
        case "Other":
          return "W tym miejscu możesz wpisać informacje o wydanych zaświadczeniach, orzeczeniach i opiniach.";
        case "Recommendation":
          return "Tutaj wpisz wszystkie informacje o tym, co zaleciłeś pacjentowi przykładowo- wykonanie badań czy konsultacje z lekarzem.<br>\
          Dodatkowo możesz opisać co zaleciłeć pacjentowi do domu - przykładowo ćwiczenia do samodzielnego wykonywania przez pacjenta, spacery czy picie dużej ilości wody.<br><br>\
          <b><font color='red'>Uwaga!</font></b><br>\
          Aby było jeszcze wygodniej, treść tego okna możesz wysłać pacjentowi mailem, pobrać lub wydrukować.";
        default:
          return "";
      }
    },
  },
  components: {
    AutosaveIcon: () => import("@/components/AutosaveIcon.vue"),
    DuplicationButton: () => import("@/components/buttons/DuplicationButton"),
    PatientCardFlag: () => import("@/components/PatientCardFlag"),
    IconButton: () => import("@/components/buttons/IconButton"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
  },
  methods: {
    onHistory() {
      this.$emit("history");
    },
    onCollapse() {
      this.$emit("collapse");
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer !important;
}
</style>